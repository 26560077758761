import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../ContextApi";
import { handleTokenInResponse } from "../Utils";
import { user_image } from "../Assets";
import { Link } from "react-router-dom";
import { APP_NUTRITION_URL , APP_PACKAGE_DETAILS_URL , APP_ORDER_URL } from "../Constants";

const User_Profile = ({ setUserOpen  , appLinkToken }) => {
  const { token, setToken } = useContext(AppContext);
  const {
    requestOptions,
    setRequestOptions,
    setEmployeeUserData,
    employeeUserData,
  } = useContext(AppContext);
  useEffect(() => {
    if (token) {
      async function CorporateDetails() {
        try {
          const response = await fetch(
            process.env.REACT_APP_API_URL + `/employee/get-corporate-details`,
            requestOptions
          );
          const tokenizedResponse = await handleTokenInResponse(response);
          const res = await tokenizedResponse.json();

          if (res.success) {
            setEmployeeUserData(res?.data?.employee);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }

      CorporateDetails();
    }
  }, []);
  return (
    <div>
      <div id="mySidebar" className="sidebar">
        <a className="closebtn" onClick={() => setUserOpen(false)}>
          ×
        </a>
        <div className="modified-quotes-heading"></div>
        <div className="addons-anime-wrapper">
          <div className="user-data-box">
            <div className="user-data-image">
              <img src={user_image} />
            </div>
            <div className="user-text-data">
              <p>{employeeUserData?.name}</p>
              <p>{employeeUserData?.mobile_no}</p>
            </div>
          </div>

          <div className="profiles-data-links">
            <div className="user-links">
              <Link to="/profile">My Profile</Link>
            </div>
            <div className="user-links">
              <Link to={`${APP_ORDER_URL}?token=${appLinkToken}`}>My Order Details</Link>
            </div>
            <div className="user-links">
              <Link to={`${APP_PACKAGE_DETAILS_URL}?token=${appLinkToken}`}>My Package Details</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User_Profile;
